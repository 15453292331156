import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function DeepFriedFunkPage() {
  return (
    <TicketshopPage
      scriptString={`<script data-name-filter="panda" src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="f8e48caf-038f-4062-b6b5-e963e15a7183" data-fixr-theme="light"></script>`}
      imageSrc="/logo/Panda Riot.png"
      backgroundSrc="/hero/Panda Riot.jpg"
      imageMaxWidth="462px"
      title="Panda Riot"
      description={`Having toured across major UK cities, the party Panda is back this year, bringing you the most groovilicious, rump shaking, happy tunes to ensure outrageous dance moves and pure euphoria. Our resident DJs will spread waves of energy across all the dancefloors from open to close with a crowd of silk shirts, big hair & sexy flares on the receiving end.`}
    />
  );
}
